.modal-search {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    max-height: 100vh;
    background: rgba(246, 244, 240, 1);
    z-index: 100;
    display: none;
    overflow: auto;
}

.modal-search__content {
    padding-top: 64px;
}

.modal-search.active {
    display: block;
}

.search {
    color: #fff;
    margin: 0 auto;
    width: 100%;
    max-width: 760px;
}

.search-form {
    display: flex;
    background: #f6f4f0;
    border-radius: 36px;
    height: 72px;
    align-items: center;
    padding-right: 24px;
    padding-left: 40px;
}

.modal-search .search-form {
    background: #fff;
}

.search-form-input {
    width: 100%;
    background: none;
    border: none;
    outline: none;
    padding-right: 24px;
    color: rgba(53, 49, 49, 1);
    font-family: 'Formular';
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.02em;
}

.search-form-input::placeholder {
    color: rgba(53, 49, 49, 0.3);
}

.search-form-clear {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-filter {
    padding: 32px 0;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.search-results {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-top: 64px;
    padding-bottom: 64px;
    max-width: 560px;
}

.search-results .highlight {
    background: rgba(255, 210, 120, 1);
}

.search-results-item a {
    text-decoration: none;
}

.search-results-item-title {
    font-family: Loos Wide;
    font-size: 26px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(53, 49, 49, 1);
}

.search-results-item-lead {
    display: block;
    margin-top: 8px;
    font-family: Formular;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    color: rgba(53, 49, 49, 0.6);
}

.search-not-found {
    padding-top: 64px;
    font-family: 'Loos Wide';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 110%;
    font-feature-settings: 'ss01' on;
    color: #202020;
}

@media screen and (max-width: 600px) {
    .modal-search__content {
        padding-left: 30px;
        padding-right: 30px;
    }
}
