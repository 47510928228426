.button {
    outline: none;
    background: none;
    border: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    vertical-align: middle;
    font-feature-settings: 'ss03' on;
    font-family: 'Formular', sans-serif;
}

.button._default {
    color: #353131;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.4px;
    padding: 8px 32px;
    height: 48px;
    border-radius: 24px;
    border: 2px solid rgba(53, 49, 49, 0.2);
}

.button._default:hover {
    border-color: rgba(53, 49, 49, 0.6);
}

.button._default._dark {
    background: #353131;
    border-color: #353131;
    color: #fff;
}

.button._dark:hover {
    background: rgba(53, 49, 49, 0.9);
}

.button._yellow {
    background: rgba(255, 210, 120, 1);
    border-color: rgba(255, 210, 120, 1);
    gap: 8px;
}

.button._yellow:hover {
    background: rgba(255, 210, 120, 1);
    border-color: rgba(255, 210, 120, 1);
}

.button._small {
    color: #353131;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0;
    padding: 4px 24px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid rgba(53, 49, 49, 0.2);
}

.button._small:hover {
    border-color: rgba(53, 49, 49, 0.6);
}

.button._small._dark {
    background: #353131;
    border-color: #353131;
    color: #fff;
}

.button._large {
    color: #353131;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 25.2px */
    letter-spacing: -0.4px;
    padding: 16px 40px;
    height: 64px;
    border-radius: 32px;
    border: 2px solid rgba(53, 49, 49, 0.2);
}

.button._large:hover {
    border-color: rgba(53, 49, 49, 0.6);
}

.button._large._dark {
    background: #353131;
    border-color: #353131;
    color: #fff;
}

.button._large._dark:hover {
    background-color: rgba(53, 49, 49, 0.9);
    border-color: rgba(53, 49, 49, 0.9);
}

@media screen and (max-width: 600px) {
    .button._large {
        width: 100%;
        max-width: 311px;
        padding-left: 0;
        padding-right: 0;
    }
}
